<template>
  <div class="vld-parent cyb-login error-page base-page">
    <login-form :hasForm="false" pageclass="darker">
      <template #text-content>
        <div class="cyb-login-misc splash">
          <div class="text-box">
            <div class="logo"><img src="/images/thinfinity-white.svg" /></div>
            <div class="content">
              <div class="base-template">
                <h1>{{ this.$t("notFound.title") }}</h1>
                <h2>{{ this.$t("notFound.subtitle") }}</h2>
                <div class="error-details">
                  {{ this.$t("notFound.details") }}
                </div>
                <div>
                  <p><br/></p>
                  <div><router-link to="/Home">{{this.$t("notFound.home")}}</router-link></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </login-form>
  </div>
</template>

<script>
import LoginForm from "../components/LoginForm.vue";
export default {
  data() {
    return {};
  },
  components: {
    LoginForm,
  },
};
</script>